import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class MenuItem extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             subcatChosenId: null,
             isMobile: window.matchMedia("(max-width: 700px)").matches
        }
    }

    componentDidMount() {
        const handler = e => this.setState({isMobile: e.matches});
        window.matchMedia("(max-width: 700px)").addListener(handler);
    }

    handleSubcatChosen = async (id, subcat) => {
        const { setSearchValue, setChosenSubcat, getItemList,
            updateSelectedCategoryIdxWhenSelectedSubcat } = this.props.myShopApiStore;
            
        setChosenSubcat(subcat);
        updateSelectedCategoryIdxWhenSelectedSubcat(subcat);
        const { selectedCategoryIdx } = this.props.myShopApiStore;  //::NOTICE:: definded here and not at top of the function, because we updated "selectedCategoryIdx" in "updateSelectedCategoryIdxWhenSelectedSubcat" method, Therefore we want the change to be up to date !!!
        await getItemList(selectedCategoryIdx, true)
        setSearchValue('');
        this.state.subcatChosenId !== id && this.setState({subcatChosenId: id})
    }

    handleCatChosen = async () => {
        const { index } = this.props;
        const { getItemList, setSearchValue, setChosenSubcat } = this.props.myShopApiStore;
        setChosenSubcat(null);
        await getItemList(index,true);
        setSearchValue('');

        this.state.isMobile && this.state.subcatChosenId !== null && this.setState({subcatChosenId: null})
    }
    
    render() {
        
        const { selectedCategoryIdx, searchValue, shop } = this.props.myShopApiStore;
        const { item, index, setSideMenuIsExtended } = this.props

        return (
            <div className={style.Container}>
                <div className={style.TopDiv}>
                    <div className={style.Cat} data-ind={index} data-code={item.codename}
                         onClick={this.handleCatChosen} onMouseEnter={() => setSideMenuIsExtended(item)}>
                        <div className={selectedCategoryIdx==index && !searchValue ? `${style.ItemIcon} ${style.ItemIconSelected}`:style.ItemIcon}>
                            <img className={style.ItemImg} src={item.icon} alt={item.name}/>
                        </div>
                        <div className={style.ItemName}>
                            {item.name}
                        </div>
                    </div>
                    <div className={style.Subcats}>
                        {
                            shop.subcategories && this.state.isMobile && (selectedCategoryIdx==index && !searchValue)
                            ?
                                shop.subcategories.map(subcat => {
                                    return(
                                        subcat.parent_id === item.id
                                        ?
                                        <div className={style.Subcat}>
                                            <div className={style.SubcatName} key={subcat.id}
                                                 style={{fontWeight: this.state.subcatChosenId === subcat.id ? 600 : null}} 
                                                 onClick={() => this.handleSubcatChosen(subcat.id, subcat)}
                                            >
                                                {subcat.name}
                                            </div>
                                        </div>
                                        :
                                            null
                                    )
                                })
                            :
                                null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default MenuItem;
