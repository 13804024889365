// import '@babel/polyfill';
import React from 'react';
import { Provider } from "mobx-react";
import Root from './Components/Root';
import * as store from "./Stores";
import { AsyncTrunk } from 'mobx-sync';
import 'react-bootstrap';
import './App.css';
import { isMobile } from 'react-device-detect';
import packageJson from "../package.json";
import { getBuildDate } from "./utils/utils";
import withClearCache from './ClearCache';
import Modal from "react-modal"

const trunk = new AsyncTrunk(store, { storage: localStorage });

trunk.init().then(() => {
    store.myShopApiStore.setInit();
    store.helperStore.setInit();
    store.storageMngr.setInit();
    console.log('trunk loaded');
});

class App extends React.Component {
    componentWillMount() {
        Modal.setAppElement('body');
    }
    render() {
        // console.log("isMobile:",isMobile)
        return(
            // isMobile
            // ?
            //      ''
            // :
                <Provider {...store}>
                    <Root/>
                </Provider>
        )
        // const ClearCacheComponent = withClearCache(MainApp);
        // return <ClearCacheComponent />
    }
}

// const MainApp = (props) => {
//     console.log("in MainApp!")

//     return (
//         isMobile
//         ?
//             ''
//         :
//             <Provider {...store}>
//                 <>
//                     <Root/>
//                     { getBuildDate(packageJson.buildDate) }
//                 </>
//             </Provider>
//     );
//   }
export default App;
