import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import Modal from "react-modal";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class MoreItemInfoModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: window.matchMedia("(max-width: 700px)").matches,
        }
    }

    componentDidMount() {
        const handler = e => this.setState({isMobile: e.matches});
        window.matchMedia("(max-width: 700px)").addListener(handler);
    }

    render() {
        const { closeModal, item } = this.props;
        const { mainColor } = this.props.myShopApiStore;
        const { barcode, itemColor, itemDepth, itemLength,
                itemMaterial, itemSize, itemWeight, itemWidth, sku } = item.product;

        return (
            <Modal
                onRequestClose={closeModal}
                isOpen={this.props.modalIsOpen} 
                style={
                    {
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,.75)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 10
                        },
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            padding: 0,
                            maxHeight: '100%',
                            maxWidth: '100%',
                            backgroundColor: "#FFF",
                            border: 'unset',
                            // height: '55vh',
                            // width: '45vw',
                            width: this.state.isMobile ? '95%' : '60%',
                        }
                    }
                }>
                <div className={style.container}>
                    <div className={style.closeBtn} onClick={closeModal}>✕</div>
                    <div className={style.body}>
                        <div className={style.itemImage}><img alt="" src={item.product.image} width="100%" /></div>
                        <div className={style.itemName} style={{color: mainColor}}>{item.product.name}</div>
                        <div className={style.details}>
                            <div className={style.detail}>
                                <div className={style.detailTitle}>כמות באריזה</div>
                                <div className={style.detailContent}>{item.unitTypes && item.unitTypes.length && 
                                                                        item.unitTypes[0].multiplier ? 
                                                                                                    item.unitTypes[0].multiplier 
                                                                                                    : 
                                                                                                    1}</div>
                            </div>
                            {
                                itemDepth
                                ?
                                <div className={style.detail}>
                                    <div className={style.detailTitle}>עומק הפריט</div>
                                    <div className={style.detailContent}>{itemDepth}</div>
                                </div>
                                :
                                    null
                            }
                            {
                                itemLength
                                ?
                                    <div className={style.detail}>
                                        <div className={style.detailTitle}>אורך הפריט</div>
                                        <div className={style.detailContent}>{itemLength}</div>
                                    </div>
                                :
                                    null
                            }
                            {
                                itemColor
                                ?
                                    <div className={style.detail}>
                                        <div className={style.detailTitle}>צבע הפריט</div>
                                        <div className={style.detailContent}>{itemColor}</div>
                                    </div>
                                :
                                    null
                            }
                            {
                                itemSize
                                ?
                                    <div className={style.detail}>
                                        <div className={style.detailTitle}>מידות הפריט</div>
                                        <div className={style.detailContent}>{itemSize}</div>
                                    </div>
                                :
                                    null
                            }
                            {
                                sku
                                ?
                                    <div className={style.detail}>
                                        <div className={style.detailTitle}>מק"ט</div>
                                        <div className={style.detailContent}>{sku}</div>
                                    </div>
                                :
                                    null
                            }
                            {
                                barcode
                                ?
                                    <div className={style.detail}>
                                        <div className={style.detailTitle}>ברקוד</div>
                                        <div className={style.detailContent}>{barcode}</div>
                                    </div>
                                :
                                    null
                            }
                            {
                                itemMaterial
                                ?
                                    <div className={style.detail}>
                                        <div className={style.detailTitle}>חומר הפריט</div>
                                        <div className={style.detailContent}>{itemMaterial}</div>
                                    </div>
                                :
                                    null
                            }
                        </div>
                        {
                            item?.product?.description
                            ?
                                <div className={style.comments}>
                                    <div className={style.titleComments}>הערות על הפריט</div>
                                    <div className={style.bodyComments}>{item.product.description}</div>
                                </div>
                            :
                                null
                        }
                    </div>
                </div>
            </Modal>
        )
    }
}

export default MoreItemInfoModal
