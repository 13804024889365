import React from "react";
import NumberFormat from "react-number-format";
import ReactTooltip from 'react-tooltip';
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import CarouselModal from "../../ItemList/Item/CarouselModal";
import MoreItemInfoModal from "../../ItemList/Item/MoreItemInfoModal";

@inject((store) => {
  const { myShopApiStore } = store;
  return { myShopApiStore };
})
@observer
class CartItem extends React.Component {
  constructor(props) {
    super(props)
    
    this.inputRef = React.createRef();
    this.state = {
       comment: '',
       moreItemInfoModalIsOpen: false,
       carouselModalIsOpen: false
    }
  }

  handleCommentChange = () => {
    if(this.inputRef.current){
      this.props.cartItem.comment = this.inputRef.current.value;
    }
  }

  openCarouselModal = () => {
    this.setState({carouselModalIsOpen: true});
  }

  closeCarouselModal = () => {
      this.setState({carouselModalIsOpen: false});
  }

  openMoreItemInfoModal = () => {
    this.setState({moreItemInfoModalIsOpen: true})
  }

  closeMoreItemInfoModal = () => {
      this.setState({moreItemInfoModalIsOpen: false})
  }

  getHENameBanner = (name) => {
    switch(name){
        case 'new':
            return 'חדש'
        case 'sale':
            return 'מבצע'
        case 'handwork':
            return 'עבודת יד'
        case 'sample':
            return 'מופת'
        case 'soon':
            return 'מגיע בקרוב'
        default:
            return ''
      }
  }

  renderBannerElement = () => {
    const { cartItem } = this.props;
    if(!cartItem?.item?.product?.productStatus){
        return null
    }

    switch(cartItem?.item?.product?.productStatus){
      case "new":
          return <div className={`${style.iFlags} ${style.iNew}`}><div>{this.getHENameBanner(cartItem?.item?.product?.productStatus)}</div></div>
      case "sample":
          return <div className={`${style.iFlags} ${style.iSample}`}><div>{this.getHENameBanner(cartItem?.item?.product?.productStatus)}</div></div>
      case "handwork":
          return <div className={`${style.iFlags} ${style.iHandwork}`}><div>{this.getHENameBanner(cartItem?.item?.product?.productStatus)}</div></div>
      default:
          return <div className={`${style.iFlags} ${style.iSale}`}><div>{this.getHENameBanner(cartItem?.item?.product?.productStatus)}</div></div>
    }
  }

  render() {
    const { removeCartItem, openCartItemForm, getItemPrice, getCartPackItemPrice, mainColor } = this.props.myShopApiStore;
    const { cartItem, index, handleCheckboxChange } = this.props;
    const product = cartItem.item.product ? cartItem.item.product : null;
    const curr = "₪";

    const iNew = <div className={`${style.iFlags} ${style.iNew}`}>חדש!</div>;
    const iSale = <div className={`${style.iFlags} ${style.iSale}`}>במבצע!</div>;
    const iSeason = <div className={`${style.iFlags} ${style.iSeason}`}>עונה!</div>;

    const hasProductOption = cartItem.item.shopProductOptions ? cartItem.item.shopProductOptions.length : false;
    const isEditableItem = hasProductOption || cartItem.item.shopToppings.length; 
    const isCartPackItem = cartItem.item.product.productType === "pack";
    const { imageBig } = cartItem.item.product;
    
    return (
      <div className={style.Container}>
        {
          imageBig && imageBig.length
          ?
            <CarouselModal modalIsOpen={this.state.carouselModalIsOpen} closeModal={this.closeCarouselModal} item={cartItem.item}/>
          :
            ''
        }
        <div className={style.RightElements}>
            <div className={style.ItemCheckbox}>
              <input
                    type="checkbox"
                    checked={cartItem.isReadyToOrder}
                    onChange={(e) => handleCheckboxChange(e, cartItem.id)}
              />
            </div>
        </div>
        <div className={style.MidElements}>
          <div className={style.ImagFlagDetalisWrapper}>
            <div className={style.ImageAndFlag}>
              <LazyLoadImage
                effect='blur'
                className={style.ItemImage}
                src={product ? product.image : ""}
                alt={product ? product.description : ""}
                onClick={this.openCarouselModal}
                style={{cursor: "pointer"}}
              />
              <div className={style.ItemFlags}>
                {this.renderBannerElement()}
              </div>
            </div>
            <div className={style.ItemDetails}>
              <div className={style.ItemName} title={cartItem.item.product.name} style={{color: mainColor}}>{cartItem.item.product.name}</div>
              <div className={style.ItemPrice}>
                <NumberFormat
                  value={isCartPackItem ? getCartPackItemPrice(cartItem) : getItemPrice(cartItem)}
                  prefix={curr}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={"text"}
                />
              </div>
              <div className={style.ItemQuantity}>
                {
                  cartItem.unitType
                  ? 
                    cartItem.unitType.type === "kg"
                    ? 
                      `${cartItem.quantity} ק"ג`
                    :
                      `${cartItem.quantity} יח'`
                  :
                    cartItem.item.unitTypes[0].type === "kg"
                    ? 
                      `${cartItem.quantity} ק"ג`
                    :
                      `${cartItem.quantity} יח'`
                }
              </div>
            </div>
          </div>
          <div className={style.Comment}>
                <div className={style.Title} onClick={() => {this.inputRef.current && this.inputRef.current.focus()}}>
                  הוסף הערה:
                </div>
                <div className={style.InputText}>
                  <input type='text' value={cartItem.comment} ref={this.inputRef} onChange={this.handleCommentChange}/>
                </div>
          </div>
        </div>
        <div className={style.LeftElements}>
          <div className={style.MoreInfo}>
            <div className={style.MoreInfoTooltip} style={{color: mainColor}}>
                <>
                  <div className={style.label} onClick={this.openMoreItemInfoModal} style={{color: mainColor}}>
                    מידע נוסף
                  </div>
                  <div className={style.modal}>
                    <MoreItemInfoModal item={cartItem.item} modalIsOpen={this.state.moreItemInfoModalIsOpen} closeModal={this.closeMoreItemInfoModal} />
                  </div>
                </>
            </div>
          </div>
          <div className={style.Buttons}>
            <div className={style.EditButton}>
              {(isEditableItem || isCartPackItem) ? (
                <button style={{backgroundColor: mainColor, border: `1px solid ${mainColor}`}} onClick={() => openCartItemForm(cartItem, isCartPackItem, true)}>
                  עדכן מוצר
                </button>
              ) : null}
            </div>
            <div className={style.RemoveButton}>
              <button style={{backgroundColor: mainColor, border: `1px solid ${mainColor}`}} onClick={() => removeCartItem(index)}>
                הסר מהסל
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CartItem;
