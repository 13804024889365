import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import MenuItem from './MenuItem';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class SideMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
             sideMenuIsExtended: false,
             subcategories: [],
             isMobile: window.matchMedia("(max-width: 700px)").matches
        }
    }

    componentDidMount() {
        const handler = e => this.setState({isMobile: e.matches});
        window.matchMedia("(max-width: 700px)").addListener(handler);
    }
    
    closeSideMenuIsExtended = () => {
        this.setState({sideMenuIsExtended: false, subcategories: []})
    }

    setSideMenuIsExtended = (category) => {
        const { shop } = this.props.myShopApiStore;

        const subcategories = shop.subcategories.filter(subcat => subcat.parent_id === category.id);
        if(subcategories.length){
            this.setState({sideMenuIsExtended: true, subcategories})
        }
        else{
            this.setState({sideMenuIsExtended: false, subcategories: []})
        }
    }

    handleSubcatChosen = async (subcat) => {
        const { setSearchValue, setChosenSubcat, getItemList,
                updateSelectedCategoryIdxWhenSelectedSubcat } = this.props.myShopApiStore;
        setChosenSubcat(subcat);
        updateSelectedCategoryIdxWhenSelectedSubcat(subcat);
        const { selectedCategoryIdx } = this.props.myShopApiStore;  //::NOTICE:: definded here and not at top of the function, because we updated "selectedCategoryIdx" in "updateSelectedCategoryIdxWhenSelectedSubcat" method, Therefore we want the change to be up to date !!!
        await getItemList(selectedCategoryIdx, true)
        setSearchValue('');
    }

    render() {
        const { categories, mainColor, chosenSubcat } = this.props.myShopApiStore;
        const chosenSubcatId = chosenSubcat ? chosenSubcat.id : null;

        if(categories && categories.length === 1){
            return null;
        }

        return (
            <>
                {
                    this.state.sideMenuIsExtended && !this.state.isMobile
                    ?
                        <div className={style.BackContainer} onMouseLeave={this.closeSideMenuIsExtended} style={{backgroundColor: mainColor }}>
                            {
                                this.state.subcategories.map(subcat => {
                                    return(
                                        <div className={style.SubcatName} key={subcat.id}
                                                style={{fontWeight: chosenSubcatId === subcat.id ? 600 : null}} 
                                                onClick={() => this.handleSubcatChosen(subcat)}
                                        >
                                            {subcat.name}
                                        </div>
                                    )
                                })
                                    
                            }
                        </div>
                    :
                        null
                }
                <div className={style.Container} style={{backgroundColor: mainColor}}>
                    
                    <div className={style.TopDiv}>
                        {
                            categories ? 
                            categories.map((item, index) => {
                                this.props.myShopApiStore.setMaxSideMenuIdx(index);
                                return (
                                    <MenuItem item={item} index={index} key={`p_${index}`}
                                              setSideMenuIsExtended={this.setSideMenuIsExtended} />
                                )
                            }) : ''
                        }
                    </div>

                </div>
            </>
        )
    }
}

export default SideMenu;