import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import MainScreen from '../MainScreen';
import cssVars from 'css-vars-ponyfill';
import Spinner from 'Components/Spinner';

@inject(store => {
    const { helperStore, myShopApiStore } = store;
    return { helperStore, myShopApiStore };
})

@observer
class Root extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            mainColor: 'gray',
        }
    }

    setLoading = () => {
        this.setState(prevState => ({
            loading: !prevState.loading
        }));
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    testInit = async () => {
        if ( this.props.helperStore.init ) {
            return true;
        }
        else {
            console.log('waiting...');
            await this.sleep(500);
            await this.testInit();
        }
    }
    // shopInit = async () => {
    //     if ( this.props.myShopApiStore.init ) {
    //         return true;
    //     }
    //     else {
    //         console.log('waiting...');
    //         await this.sleep(500);
    //         await this.shopInit();
    //     }
    // }
    logout = () => {
        this.setLoading();
        this.props.myShopApiStore.resetAll();
        setTimeout(() => {
            this.setLoading();
        }, 500);
    }

    componentDidMount = async () => {
        const { getShopsResp, setShopId, setShopName, shopsData, closeStore } = this.props.myShopApiStore;
        cssVars();
        // await this.shopInit();
        if(this.props.myShopApiStore.init){
            this.setState({mainColor: this.props.myShopApiStore.getMainColor()});
    
            ////////////////////////for single store!!!!! //////////////
            const { openStore, shopId,shop } = this.props.myShopApiStore;
            // const isSingleStore = !shopsData && shopId === 1;
            let storeName = shop ? shop.name : ""
            // isSingleStore && await openStore(shopId, storeName)
            await openStore(shopId, storeName)
            ////////////////////////////////////////////////////////////
    
            if(this.props.myShopApiStore.user_info != null){} 
            else {
                this.props.myShopApiStore.generateCsrf();
            }
            
            this.setState({loading: false})
            // this.setLoading();
        }
    }

    
    render() {
        const { storeOpened, shopsData, shopId, closeStore, openStore, my_env } = this.props.myShopApiStore;
        const isSingleStore = !shopsData && shopId === 1; // changed for 'katif'
        // const isSingleStore = !shopsData; //for 'Katif'

        // const pathname = window.location.pathname.slice(1);
        // pathname && this.tryOpenStore();
        return(
            <>
                {/* <Spinner loading={this.state.loading} color="#000" style={{zIndex: 100}}/> */}
                <MainScreen mainColor={this.mainColor}/>
            </>
        )
    }
}

export default Root;

